$(document).ready(function () {

    // make all textareas auto expand on input
    autosize($('textarea'));

    // setupRegisterBtn();

}); // document ready

//$(document).on("keypress", ".registerFormElement", function (e) {
//    if (e.keyCode == 13) {
//        registerUser();
//    }
//});

//function setupRegisterBtn() {
//    debug("Setting up register button.");
//    $("#register-btn").blur();
//    $(document).on("click", "#register-btn", function () {
//        if (!$('.register-form').is(":visible")) {
//            $('.register-form').show();
//            $('#newUserName').focus();
//            $('#register-btn').text(i18n.gettext("Sign up"));
//        } else {
//            registerUser();
//        }
//    });
//}

function registerUserFormKeyPress(elem, event) {
    if (event.keyCode == 13) {
        registerUser(elem);
    }
}

function registerUser(elem) {
    debug("Initializing registration process.");
    
    $(elem).attr("disabled","disabled");

//    $(document).off("click", "#register-btn");
//    $(document).off("keypress", ".registerFormElement");

    var couponcodeGoto = '';
    if ($('#couponcode').length > 0) {
        couponcodeGoto = 'coupon?couponCodeDefault=' + $('#couponcode').val();
    }

    var newUserName = encodeURIComponent($("#newUserName").val());
    var newUserPass = encodeURIComponent($("#newUserPass").val());
    var newUserPassControl = encodeURIComponent($("#newUserPassControl").val());
    var newUserMail = encodeURIComponent($("#newUserMail").val());

    var subscribeToNewsletter = false;
    if ($("#subscribeToNewsletter").length > 0) {
        subscribeToNewsletter = $("#subscribeToNewsletter").is(":checked");
    }

    var addToSalesManago = false;
    if ($(".newsletter-check").length > 0) {
        addToSalesManago = $("#order-newsletter").is(":checked");
    }

    var invitation;
    var generalInvitation;
    if ($("#invitation").length > 0) {
        invitation = $("#invitation").val();
    }
    if ($("#generalInvitation").length > 0) {
        generalInvitation = $("#generalInvitation").val();
    }

    var nonce = getNonce();

    if (newUserName == "") {
        flashModal(i18n.gettext("Please enter a username."));
        setupRegisterBtn();
    } else if (newUserPass == "") {
        flashModal(i18n.gettext("Please enter a password."));
        setupRegisterBtn();
    } else if (newUserPassControl == "") {
        flashModal(i18n.gettext("Please enter the password again in the field next to the first password field."), 3500);
        setupRegisterBtn();
    } else if (newUserMail == "") {
        flashModal(i18n.gettext("Please enter your e-mail-address."));
        setupRegisterBtn();
    } else if ($(".regulamin-check").length > 0 && !$(".regulamin-check").is(":checked")) {
        flashModal(i18n.gettext("You have to accept the terms of the regulations at first."));
        setupRegisterBtn();
    } else {
        flashModal(i18n.gettext("Started Registration..."));
        $.ajax({
            // retry-sensitive
            type: "POST",
            url: "landing",
            context: this,
            data: "newUserName=" + newUserName
                    + "&newUserPass=" + newUserPass
                    + "&newUserPassControl=" + newUserPassControl
                    + "&newUserMail=" + newUserMail
                    + "&invitation=" + invitation
                    + "&generalInvitation=" + generalInvitation
                    + "&subscribeToNewsletter=" + subscribeToNewsletter
                    + "&nonce=" + nonce
        }).done(function (data) {

//                try {
            var obj = JSON.parse(data.trim());

            if (obj.success === true) {
                flashModal(i18n.gettext("Successfully signed up."));

                if (typeof (ga) !== 'undefined') {
                    ga('send', 'event', 'user', 'register');
                }
                if (typeof (hj) !== 'undefined') {
                    //hj('trigger', 'landing');
                }

                $.ajax({
                    type: "POST",
                    url: "landing",
                    context: this,
                    data: "loginusername=" + encodeURIComponent($("#newUserName").val()) + "&loginuserpass=" + encodeURIComponent($("#newUserPass").val()) + "&stayLoggedIn=false",
                    success: function (data) {
//                                try {
                        var obj = JSON.parse(data.trim());
                        if (obj.success === true) {

                            if (addToSalesManago) {
                                debug("Logged in - now requesting addition to SalesManago.");
                                $.get("salesManago?do=register", function (salesManagoData) {
//                                    var x = window.open('', '', 'width=600, height=600');
//                                    x.document.open();
//                                    x.document.write(salesManagoData);
//                                    x.document.close();

                                    if ($('#courseOneTimeId').length > 0 && $('#courseOneTimePassword').length > 0) {
                                        joinCourseByOneTimeData(function () {
                                            window.location = 'welcome';
                                        });
                                    } else if ($('#courseId').length > 0 && $('#courseCode').length > 0) {
                                        window.location = 'course-join?courseId=' + $('#courseId').val() + '&courseCode=' + $('#courseCode').val();
                                    } else if ($('#courseId').length > 0 && $('#coursePassword').length > 0) {
                                        window.location = 'course-join?courseId=' + $('#courseId').val() + '&coursePassword=' + $('#coursePassword').val();
                                    } else {
                                        if (couponcodeGoto != '') {
                                            window.location = couponcodeGoto;
                                        } else {
                                            window.location = obj.goto;
                                        }
                                    }

                                });
                            } else {
                                if ($('#courseOneTimeId').length > 0 && $('#courseOneTimePassword').length > 0) {
                                    joinCourseByOneTimeData(function () {
                                        window.location = 'welcome';
                                    });
                                } else if ($('#courseId').length > 0 && $('#courseCode').length > 0) {
                                    window.location = 'course-join?courseId=' + $('#courseId').val() + '&courseCode=' + $('#courseCode').val();
                                } else if ($('#courseId').length > 0 && $('#coursePassword').length > 0) {
                                    window.location = 'course-join?courseId=' + $('#courseId').val() + '&coursePassword=' + $('#coursePassword').val();
                                } else {
                                    if (couponcodeGoto != '') {
                                        window.location = couponcodeGoto;
                                    } else {
                                        window.location = obj.goto;
                                    }
                                }
                            }
                        } else {
                            flashModal(obj.message);
                            // setupRegisterBtn();
                        }
//                                } catch (e) {
//                                    sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                                    return;
//                                }
                    }
                });

            } else {
                flashModal(obj.answer);
                // setupRegisterBtn();
            }
//                } catch (e) {
//                    sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                    return;
//                }

        }).fail(function (xhr, status) {
            flashModal(i18n.gettext("No connection to server. Please try again later!"));
        }).complete(function() {
            $(elem).removeAttr('disabled');
        });
    } // end else
} // end fn registerUser



function registerBtnBottomClick() {
    if (!$('.register-form').is(":visible")) {
        $('.register-form').show();
        $('#register-btn').text(i18n.gettext("Sign up"));
    }

    $('html, body').animate({
        scrollTop: $(".register-form").offset().top - 20
    }, 400, "swing", function () {
        $('#newUserName').focus();
    });
}

function createDemoAccount() {
    flashModal(i18n.gettext("One moment, please..."));
    $.getJSON("api/v5/createdemoaccount", function (result) {
        $.ajax({
            type: "POST",
            url: "landing",
            data: "loginusername=" + result.userName + "&loginuserpass=" + result.password,
            success: function (data) {
                var obj = JSON.parse(data.trim());
                if (obj.success === true) {
                    window.location = obj.goto;
                } else {
                    flashModal(i18n.gettext("Login failed."), "default", "error");
                }
            }
        });
    });
}