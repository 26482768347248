var flashModalTimeout;

$(document).ready(function () {

    // TEMP CODE FOR ACTIVITY MODAL TESTING

    // open activity modal
    /*
     $(document).on('click',".activity-bar .activity", function() {
     $("#activity-modal").modal();
     $("#activity-modal").find(".comment-textarea").css("height",38);
     });
     */



    // MODALS
    $.modal.defaults = {
        overlay: "#000", // Overlay color
        opacity: 0.75, // Overlay opacity
        zIndex: 64000, // Overlay z-index.
        escapeClose: true, // Allows the user to close the modal by pressing `ESC`
        clickClose: true, // Allows the user to close the modal by clicking the overlay
        closeText: 'Close', // Text content for the close <a> tag.
        closeClass: '', // Add additional class(es) to the close <a> tag.
        showClose: false, // Shows a (X) icon/link in the top-right corner
        modalClass: "modal", // CSS class added to the element being displayed in the modal.
        spinnerHtml: null, // HTML appended to the default spinner during AJAX requests.
        showSpinner: true, // Enable/disable the default spinner during AJAX requests.
        fadeDuration: 200, // Number of milliseconds the fade transition takes (null means no transition)
        fadeDelay: 0          // Point during the overlay's fade-in that the modal begins to fade in (.5 = 50%, 1.5 = 150%, etc.)
    };



    $('#pro-info-modal').on($.modal.CLOSE, function (event, modal) {
        $(".js-pro-info-modal-btn").removeClass("active");
    });

    $('#free-info-modal').on($.modal.CLOSE, function (event, modal) {
        $(".js-free-info-modal-btn").removeClass("active");
    });

    $('#search-modal').on($.modal.CLOSE, function (event, modal) {
        $(".js-search-modal-btn").removeClass("active");
    });

    // Clear flash-modal close timeout when closing it manually. If it isn't cleared, modals opened directly after will be closed automatically.
    $(".flash-modal").on($.modal.CLOSE, function (event, modal) {
        clearTimeout(flashModalTimeout);
    });


}); // document ready

function closeModal() {
    debug('close modal');
    $(".expandModalButton").hide();
    $(".compressModalButton").hide();
    // setTimeout(function () {
        $.modal.close();
//        setTimeout(function () {
            $(".modal").hide(); // Important because the closeModal function seems to work unreliable: The darkness disappears, but the modal remains.
            $(".jquery-modal.blocker").fadeOut("fast", function () {
                // Animation complete.
                $(".jquery-modal.blocker").remove();
            });
            $(".modal").removeClass("current");
//        }, 150);
    // }, 150);
}

function closeFlashModalIfOld() {
    var currentTimeStampInSeconds = Math.floor(Date.now() / 1000);
    var flashModalTimeStamp = $("#flashModalOpened").val();
    if (currentTimeStampInSeconds - flashModalTimeStamp > 5) {
        $.modal.close();
    }
}

function updateProInfoSentence() {
    $.ajax({
        type: "GET",
        url: "profile?proInfoSentence=1",
        data: "dynReq=1",
    }).done(function (data) {
        $("#pro-info-modal .pro-info-sentence").html(data);
    });
}

// open pro-info modal button
function proInfoModal() {
    $("#pro-info-modal").modal();
    updateProInfoSentence();
}

// open free-info modal button
function freeInfoModal() {
    $("#free-info-modal").modal();
}

function closeCardModal() {
    $('#card-modal-content').html('');
    closeModal();
}

//flash a modal message
function flashModal(flashMessage, timeout, type) {
    var timeoutDefault = 2000;
    var typeDefault = "success";

    if (isNaN(timeout)) {
        timeout = timeoutDefault;
    } else if (timeout === "default") {
        timeout = timeoutDefault;
    }

    type = (type) ? type : typeDefault;

    debug("# Flash message: " + flashMessage);
    debug("# Timeout: " + timeout);
    // timeoutVal = typeof timeoutVal !== 'undefined' ? timeoutVal : 1700;

    // irgendein anderes modal offen?
    if ($(".modal").not('.flash-modal').hasClass('current')) {
        $.when($.modal.close()).done(function () {
            displayFlashModal(flashMessage, timeout, type);
        });
    } else {
        debug("No other modal open ...");
        displayFlashModal(flashMessage, timeout, type);
    }
}



function displayFlashModal(flashMessage, timeout, type) {
    var currentTimeStampInSeconds = Math.floor(Date.now() / 1000);
    $("#flashModalOpened").val(currentTimeStampInSeconds);
    var flashModal = $(".flash-modal");
    flashModal.attr("data-type", type); // set data-attribute with .attr(), not jQuery's .data() because it needs to show up in the HTML, to be used for css styling!
    flashModal.find("p").html(flashMessage);
    if (!flashModal.hasClass('current')) {
        flashModal.modal();
    }
    clearTimeout(flashModalTimeout);
    flashModalTimeout = setTimeout(function () {
        closeModal();
    }, timeout);
}



// Repetico Pro User prompt modal
function repeticoPromptModal(promptMessage) {
    if ($("html").data("theme") == "avoxa") {
        loadPageByAjax("requestProAccount")
    } else {
        clearTimeout(flashModalTimeout);
        // closeModal();
        $(".repetico-prompt-modal").find(".modal-prompt-text").html(promptMessage);
        $(".repetico-prompt-modal").modal();
    }
}

function removeInputX(el) {
    $(el).parent().remove();
}

$(document).on('modal:open', function () {
    // DISABLE PAGE SCROLL WHEN OPENING MODAL
    disablePageScroll();

    // deactivate learn-shortcuts on learning page when opening modal
    if ($(".page-container.lernen").length > 0) {
        $(document).off('keyup.learn-shortcuts');
    }
});

$(document).on('modal:close', function () {
    // REENABLE PAGE SCROLL WHEN MODAL CLOSES
    reenablePageScroll();

    // reactivate learn-shortcuts on learning page when closing modal
    if ($(".page-container.lernen").length > 0) {
        $(document).on('keyup.learn-shortcuts', '.page-lernen', function (e) {
            return keyPressActionLearning(e);
        });
    }
});

function saveCookieSettings(acceptAll) {
    if (typeof acceptAll === 'undefined') {
        acceptAll = false;
    }
    var necessary = $("#cookie-selection-technically-necessary").is(":checked");
    var statistics = $("#cookie-selection-statistics").is(":checked");
    if (acceptAll) {
        necessary = true;
        statistics = true;
    }
    var cookieSelection = "n";
    if (necessary && statistics) {
        var cookieSelection = "a";
    }
    setCookie("cookieSelection", cookieSelection, 365);
}