/*
 function load_resource(url) {
 var req = new XMLHttpRequest();
 req.open('GET', url, false);
 //XHR binary charset opt by Marcus Granado 2006 [http://mgran.blogspot.com]
 req.overrideMimeType('text\/plain; charset=x-user-defined');
 req.send(null);
 if (req.status != 200)
 return '';
 return req.responseText;
 }
 */

/*
 var catalogUrl = 'http://localhost/repetico/ediscio_2014/i18n/af.mo';
 var i18n;
 
 var xhr = new XMLHttpRequest();
 xhr.open('GET', catalogUrl, true);
 xhr.responseType = 'arraybuffer';
 
 xhr.onload = function (e) {
 // response is unsigned 8 bit integer
 // var responseArray = new Uint8Array(this.response);
 var options = {
 encoding: 'utf-8',
 domain: 'messages'
 }
 var locale_data = jedGettextParser.mo.parse(this.response,options);
 i18n = new Jed({
 'locale_data': locale_data,
 'domain': 'messages'
 });
 };
 
 xhr.send();
 */

var repeticoLocale = $("html").data("locale");
debug("Loading translations for locale: " + repeticoLocale);
if (repeticoLocale == "") {
    repeticoLocale = "en_GB";
}
var catalogUrl = $("html").data("approot") + 'i18n/' + $("html").data("appversion") + '/js-' + repeticoLocale + '.json';
var i18n = new Jed({
    'locale_data': {
        "messages": {
            "": {
                "domain": "messages",
                "plural_forms": "nplurals=2; plural=(n != 1);",
                "lang": "en"
            },
            "Hallo Welt!": ["Hello world!"]
        }
    },
    'domain': 'messages'
});
i18nLoaded = false;
$.get(catalogUrl, function (data) {
    /*
     var moBuffer = new ArrayBuffer();
     moBuffer = str2ab(data);
     var locale_data = jedGettextParser.mo.parse(moBuffer);
     i18n = new Jed({
     'locale_data': locale_data,
     'domain': 'messages'
     });
     */
    i18n = new Jed(data);
    i18nLoaded = true;
});
