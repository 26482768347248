/* SUPPORT MODAL */

// open support modal button
function supportModal() {
    $("#support-modal").modal();
    setTimeout(function () {
        $("#support-modal").find(".input-name").focus();
    }, 0);
}

// send support question
function sendSupportQuestion(formIdentifier) {
    
    var name;
    var mail;
    var message;
    var phone;
    
    if ($(formIdentifier + " .input-name-contactform").length > 0) {
        name = $(formIdentifier + " .input-name-contactform").val();
    }
    if ($(formIdentifier + " .input-mail-contactform").length > 0) {
        mail = $(formIdentifier + " .input-mail-contactform").val();
    }
    if ($(formIdentifier + " .input-phone-contactform").length > 0) {
        phone = $(formIdentifier + " .input-phone-contactform").val();
    }
    if ($(formIdentifier + " .input-message-contactform").length > 0) {
        message = $(formIdentifier + " .input-message-contactform").val();
    } else {
        message = encodeURIComponent($('#support-modal').find("textarea").val());
    }
    debug("Support-message from " + name + " with mail address " + mail + " and phone number " + phone + ": " + message);
    
    // flashModal(i18n.gettext('Sending message...'));
    if (message.trim() == '') {
        debug("Message is empty.");
        flashModal(i18n.gettext("Please enter a message!"));
    } else {
        if (name.trim() == '') {
            debug("Name is empty.");
            flashModal(i18n.gettext("Please enter a name!"));
        } else {
            if ($(formIdentifier + " .input-mail-contactform").length > 0 && mail.trim() == '') {
                debug("Mail address is empty.");
                flashModal(i18n.gettext("Please enter a mail address!"));
            } else if (validateEmail(mail.trim()) == false) {
                flashModal(i18n.gettext("Please enter a valid mail address!"));
            } else {
                $.ajax({
                    type: "POST",
                    url: "support",
                    data: "mail=" + mail + "&name=" + name + "&phone=" + phone + "&message=" + message,
                    success: function (data) {
                        flashModal(data, 3000);
                    }
                });
            }
        }
    }
    
}
