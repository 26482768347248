// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());



// global var for page scrolling. if <=0 it page scroll should be disabled.
// needed for the use case when the click outside that reenables page scrolling is on a button that disables page scrolling.
// in that case page scrolling would be disabled first and then reenabled, which is the wrong oder!
// initial value is 1, because on page load there is never a popup or modal open!
var pageScroll = 1;

var currentScroll=0;

function disablePageScroll() {
	pageScroll--;
	if (!pageScroll) {
		debug('disable page scrolling');
		currentScroll=$(window).scrollTop();
		$("body").css("position","fixed");
		$("body").css("width","100%");
		$("body").css("top",-currentScroll);
	}
}

function reenablePageScroll() {
	pageScroll++;
	if (pageScroll) {
		debug('reenable page scrolling');
		$("body").css("position","relative");
		$("body").css("width","100%");
		$("body").css("top","auto");
		$(window).scrollTop(currentScroll);
	}
}
