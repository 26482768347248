var repeticoDebuggingEnabled = document.getElementsByTagName("html")[0].getAttribute("data-debug");

if (repeticoDebuggingEnabled == 1) {
    console.log("--- Debugging enabled. ---");
}
   
function debug(text) {
    if (repeticoDebuggingEnabled == 1) {
        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        console.log('[DEBUG] [' + time + '] ' + text);
    }
}

