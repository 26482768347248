function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) {
        return parts.pop().split(";").shift();
    } else {
        return null;
    }
}

function setCookie(c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value)
            + ((exdays == null) ? "" : ";path=/;expires=" + exdate.toUTCString());
    debug("Setting cookie: " + c_name + "=" + c_value);
    document.cookie = c_name + "=" + c_value;
}